/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}
.n-d-block-m {
}
    .n-d-block-m > div > p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: #333333;
        font-family: 'Istok Web';
    }
    .n-d-block-m > div > h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 130%;
        color: #333333;
        font-family: 'Open Sans';
    }
    .n-d-block-m > div > hr {
        border: 0.5px solid #BDBDBD;
        height: 0px;
    }
    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }
.n-d-block-m > div > input {
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    min-width: 520px;
    line-height: 23px;
    font-family: 'Istok Web';
    color: #333;
}
.n-d-block-m > div > button {
    background: #EF8F22;
    border-radius: 2px;
    font-family: 'Open Sans';
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 220px;
}

    input [type=text], input[type=password] {
        border-radius: 2px;
        border: 1px solid rgb(230,234,238);
        padding: 4px 12px;
    }
   /* input[type=text]:focus, input[type=password]:focus {
        -webkit-box-shadow: 0px 0px 3px 2px rgba(29, 172, 187, 0.15);
        -moz-box-shadow: 0px 0px 3px 2px rgba(29, 172, 187, 0.15);
        box-shadow: 0px 0px 3px 2px rgba(29, 172, 187, 0.15);
        transition: 0.2s;
        outline: none;
    }*/
.n-table {
    width: 100%;
}
    .n-table > tr {
     
    }
    .n-table > tr > th {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #A7A9AC;
    }
        .n-table > tr > td {
            font-family: 'Istok Web';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-align: center;
            height: 58px;
            line-height: 26px;
            color: #333;
        }
            .n-table > tr > td > button {
                border: 2px solid #EF8F22;
                box-sizing: border-box;
                border-radius: 2px;
                font-family: 'Open Sans';
                background-color: white;
                cursor: pointer;
                max-width: 184px;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #EF8F22;
            }
            p {
        color: rgb(37,46,56);
    }
.file-button {
    display: flex;
    align-items: center;
}
    .file-button > p {
        border: 2px solid #EF8F22;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #EF8F22;
        font-family: 'Open sans';
    }
    .file-button > i {
        margin-left: 28px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #7C7C7C;
        font-family: 'Istok web';
    }
.attach-block {
    border-radius: 2px;
    margin-top: 8px;
    width: 300px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
    .attach-block > p {
        border: 1px solid rgb(230,234,238);
        margin-bottom: -1px;
        padding: 4px;
        height: 35px;
    }
.data-block {
    border: 1px solid rgb(230,234,238);
    padding: 4px;
    border-radius: 2px;
    width: 300px;
    margin-bottom: 8px;
}
button {
    background-color: rgb(230,234,238);
    color: rgb(37,46,56);
    width: 300px;
    height: 50px;
    border: none;
}
   /* button:hover {
        -webkit-box-shadow: 0px 0px 3px 2px rgba(70, 47, 153, 0.15);
        -moz-box-shadow: 0px 0px 3px 2px rgba(70, 47, 153, 0.15);
        box-shadow: 0px 0px 3px 2px rgba(70, 47, 153, 0.15);
        transition: 0.3s;
    }*/
.test {
    padding-top: 70px;
}
        .test > p {
            width: 740px;
            background: white;
            padding: 0 10px;
            border-radius: 2px;
            -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        }
.table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

    .table > tr > td, .table > tr > th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

.table > tr:nth-child(even) {
    background-color: #dddddd;
}